import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function Home(props) {

    loadjs('/js/active.js');

    useEffect(() => {
        props.updateMetaTags({
            title: 'BoxUp Shopify App Development',
            description: "Building apps to make things simpler for you.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Shopify, shopify app developer, shopify apps'
                }
            }
        })
    }, []);

    return <>
        <section className="welcome-area hero4" id="home">
            <div className="hero4-bg-shape"><img src="img/core-img/hero-4.png" alt=""/></div>
            <div className="hero4-bg-shape2"><img src="img/core-img/hero-4-2.png" alt=""/></div>
            <div className="container">
                <div className="row h-100 align-items-center">
                    <div className="col-12 col-md-8">
                        <div className="welcome-content owl-carousel hero-slides pr-3">
                            <div className="single-hero-slide mr-xxl-5">
                                <span className="key-quote" data-animation="fadeInUp" data-delay="300ms">Try with 7 day free trial</span>
                                <h2 data-animation="fadeInUp" data-delay="500ms">The New <br/>Box Builder App</h2>
                                <p className="mb-4" data-animation="fadeInUp" data-delay="750ms">
                                    A perfect solution for selling gift boxes or customised packaged products in a step-by-step builder. 
                                    Design each step of the build-a-box process and even collect additional information, such as gift card message, too.
                                </p>
                                <a className="btn saasbox-btn white-btn mr-3 mt-3" href="https://apps.shopify.com/boxup-product-builder" target="_blank" data-animation="fadeInUp" data-delay="1000ms">See Features</a>
                            </div>
                            <div className="single-hero-slide mr-xxl-5">
                                <span className="key-quote" data-animation="fadeInUp" data-delay="300ms">
                                    Free Trial Coming Soon
                                </span>
                                <h2 data-animation="fadeInUp" data-delay="500ms">It's Time to <br/>Unify Shopify</h2>
                                <p className="mb-4" data-animation="fadeInUp" data-delay="1000ms">
                                    An app designed to allow Shopify stores to work together and support each other's growth. We'll be announcing more shortly so 
                                    stay tuned for more information and the app's release before the end of 2020.
                                </p>
                                <a className="btn saasbox-btn white-btn mr-3 mt-3" disabled data-animation="fadeInUp" data-delay="1000ms">Coming Soon</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4" id="app-pics">
                        <div className="card hero-video-card">
                            <div className="video-shape">
                                <img src="img/core-img/hero-3-2.png" alt=""/>
                            </div>
                            <div className="video-card-slides owl-carousel">
                                <img src="img/builder-icon.png" alt="BoxUp's Box Builder"/>
                                <img src="img/unify1024.png" alt="BoxUp's Unify Shopify App"/>
                            </div>
                            <a style={{display: 'none'}} className="video-play-btn" href="https://www.youtube.com/watch?v=X_93H_60Pls">
                                <i className="lni-play"></i><span className="video-sonar"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="service-area service3 section-padding-120 bg-gray" id="service">
            <div className="container">           
                <div className="row g-5 justify-content-center">
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="card service-card wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                        <div className="card-body">
                            <div className="icon"><i className="lni-gift"></i></div>
                            <h4>Box Builder App</h4>
                            <p>Design your gift box or custom product building process.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="card service-card active wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1000ms">
                    <div className="card-body">
                        <div className="icon"><i className="lni-handshake"></i></div>
                        <h4>Unify Shopify App</h4>
                        <p>It's purpose is to bring Shopify stores together. More coming soon.</p>
                    </div>
                    </div>
                </div>
                <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="card service-card wow fadeInUp" data-wow-delay="800ms" data-wow-duration="1000ms">
                    <div className="card-body">
                        <div className="icon"><i className="lni-question-circle"></i></div>
                        <h4>What do you think?</h4>
                        <p>Please let us know if you have any app ideas you'd like to see.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="saasbox-news-area section-padding-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-9 col-lg-7 col-xxl-6">
                            <div className="section-heading text-center">
                                <h6>Latest info</h6>
                                <h2>Our Latest News</h2>
                                <p>A few updates from us on our app releases and current in-development apps.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center g-5">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                            <div className="card blog-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                                <Link to="/news/box-builder-release"><img className="card-img-top" src="img/LIVE.png" alt=""/></Link>
                                <div className="post-content p-4">
                                    <Link className="d-block text-muted mb-2" to="/news/box-builder-release">Sep 2020</Link>
                                    <Link className="post-title d-block mb-2" to="/news/box-builder-release">
                                        <h4>Our Box Builder app is live!</h4>
                                    </Link>
                                    <p>Start with a 7 day free trial and let us know what you think.</p>
                                    <div className="post-meta d-flex align-items-center justify-content-between">
                                        <Link className="post-author" to="/news/box-builder-release">
                                            <img src="img/boxup.png" alt=""/>
                                        </Link>
                                        <span className="text-muted">1 min read</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                            <div className="card blog-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                                <Link to="/news/unify-development"><img className="card-img-top" src="img/development_unify.png" alt=""/></Link>
                                <div className="post-content p-4">
                                    <Link className="d-block text-muted mb-2" to="/news/unify-development">Sep 2020</Link>
                                    <Link className="post-title d-block mb-2" to="/news/unify-development">
                                        <h4>Unify app now in development.</h4>
                                    </Link>
                                    <p>We've completed the design phase of our Unify app.</p>
                                    <div className="post-meta d-flex align-items-center justify-content-between">
                                        <Link className="post-author" to="/news/unify-development">
                                            <img src="img/boxup.png" alt=""/>
                                        </Link>
                                        <span className="text-muted">1 min read</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                            <div className="card blog-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                                <Link to="/news/boxup-news"><img className="card-img-top" src="img/boxup_news.png" alt=""/></Link>
                                <div className="post-content p-4">
                                    <Link className="d-block text-muted mb-2" to="/news/boxup-news">July 2020</Link>
                                    <Link className="post-title d-block mb-2" to="/news/boxup-news">
                                        <h4>We're getting things started.</h4>
                                    </Link>
                                    <p>BoxUp plans to build some awesome Shopify apps.</p>
                                    <div className="post-meta d-flex align-items-center justify-content-between">
                                        <Link className="post-author" to="/news/boxup-news">
                                            <img src="img/boxup.png" alt=""/>
                                        </Link>
                                        <span className="text-muted">2 min read</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="client-feedback-area d-md-flex align-items-center justify-content-between section-padding-120">
                <div className="client-shape"><img src="img/core-img/testimonial.png" alt=""/></div>
                <div className="client-feedback-heading">
                    <div className="section-heading mb-0 text-right">
                    <h6>Testimonials</h6>
                    <h2 className="mb-0">Our Customer Reviews</h2>
                    </div>
                </div>
                <div className="client-feedback-content">
                    <div className="client-feedback-slides owl-carousel">
                        <div className="card feedback-card">
                            <div className="card-body"><i className="lni-quotation"></i>
                            <p>It's made it much easier for our customers to create their perfect gift box, thank you!</p>
                                <div className="client-info d-flex align-items-center">
                                    <div className="client-name">
                                        <h6>Leanne</h6>
                                        <p>Box Builder App</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card feedback-card">
                            <div className="card-body"><i className="lni-quotation"></i>
                            <p>Just what we needed for our customisable products and great support, too.</p>
                                <div className="client-info d-flex align-items-center">
                                    <div className="client-name">
                                        <h6>Jason</h6>
                                        <p>Box Builder App</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cta-area cta3 py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-12 col-sm-8">
                            <div className="cta-text mb-4 mb-sm-0">
                            <h4 className="text-white mb-0">Take a look at our apps over on Shopify's app store.</h4>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 text-sm-right">
                            <a className="btn saasbox-btn white-btn" target="_blank" href="https://apps.shopify.com/partners/applow">Our Apps</a>
                        </div>
                    </div>
                </div>
            </section>
            <div className="cookiealert mb-0" role="alert">
            <p>This site uses cookies. We use cookies to ensure you get the best experience on our website. For details, please check our <a href="#" target="_blank"> Privacy Policy.</a></p>
            <button className="btn btn-primary acceptcookies" type="button" aria-label="Close">I agree</button>
        </div>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(Home);