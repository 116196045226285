import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function Privacy(props) {

    useEffect(() => {
        loadjs('/js/active.js');
        props.updateMetaTags({
            title: 'Privacy Policy - BoxUp Shopify App Development',
            description: "Building apps to make things simpler for you.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Shopify, shopify app developer, shopify apps'
                }
            }
        });
    }, []);

    return <>
        <div className="saasbox--blog--area section-padding-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8">
                        <div className="single-blog-details-area">
                            <h2 className="mb-3">Privacy Policy</h2>
                            <p>
                                BoxUp provides Shopify Applications, "the Service", to merchants who use Shopify to power their stores. 
                                This Privacy Policy describes how personal information is collected, used, and shared when you install or use any app in connection with your Shopify-supported store.
                            </p>
                            <h5>Personal Information any app Collects</h5>
                            <p>
                                When you install any app, we are automatically able to access certain types of information from your Shopify account, such as your store's name, URL, Shopify plan, product and order information.
                            </p>
                            <p>
                                Information about you and others who may access any app on behalf of your store, such as your name, address, email address, phone number, and billing information; 
                                Information about individuals who visit your store, such as their IP address, web browser details, time zone, and information about the cookies installed on the particular device.
                            </p>
                            <p>
                                Any email address collected may become a recipient of our newsletters, when appropriate, to make you aware of our app changes or newly available apps.
                            </p>
                            <p>
                                We collect personal information directly from the relevant individual, through your Shopify account, or using the following technologies: 
                            </p>
                            <p>
                                “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. 
                            </p>
                            <p>
                                “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                            </p>
                            <h5>How Do We Use Your Personal Information?</h5>
                            <p>
                                We use the personal information we collect from you and your customers in order to provide the Service and to operate any app. 
                                Additionally, we use this personal information to: Communicate with you; Optimize or improve any app; and Provide you with information or advertising relating to our products or services. 
                            </p>
                            <h5>Sharing Your Personal Information</h5>
                            <p>
                                Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                            </p>
                            <h5>Behavioural Advertising</h5> 
                            <p>
                                As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. 
                                For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                            </p>
                            <h5>Your Rights</h5>
                            <p>
                                If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                            </p>
                            <p>
                                Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. 
                                Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
                            </p>
                            <h5>Data Retention</h5> 
                            <p>
                                When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                            </p>
                            <h5>Changes</h5> 
                            <p>
                                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                            </p>
                            <h5>Contact Us</h5>
                            <p>
                                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@boxup.io.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(Privacy);