import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function Contact(props) {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const submit = async(e) => {
        e.preventDefault();
        setLoading(true);
        let res = await sendContact();
        if (res) {
            var formMessages = window.$('#success_fail_info');
            window.$(formMessages).removeClass('alert alert-danger');
            window.$(formMessages).addClass('alert alert-success');
            window.$(formMessages).text('Thank you! Your message has been sent.');
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
        } else if (res.error || !res) {
            window.$(formMessages).removeClass('alert alert-success');
            window.$(formMessages).addClass('alert alert-danger');
            window.$(formMessages).text('Oops! An error occured.');
        }
        setLoading(false);
    }

    const sendContact = () => {
        return new Promise((resolve, reject) => {
            const endpoint = `https://us-central1-boxup-io.cloudfunctions.net/api/dropship/contact`;
            fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify({
                    "name": name,
                    "email": email,
                    "subject": subject,
                    "message": message
                })
            })
            .then((data) => data.json())
            .then((res) => resolve(res))
            .catch((err) => { 
                console.log(err);
                reject(err); 
            });
        });
    }

    useEffect(() => {
        loadjs('/js/active.js');
        props.updateMetaTags({
            title: 'Contact Us - BoxUp Shopify App Development',
            description: "Building apps to make things simpler for you.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Shopify, shopify app developer, shopify apps'
                }
            }
        })
    }, []);
    
    return <>
        <div className="breadcrumb--area white-bg-breadcrumb">
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                    <h2 className="breadcrumb-title">Contact Us</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="saasbox-contact-us-area section-padding-120-40">
            <div className="container">                       
                <div className="row justify-content-between">
                <div className="col-12 col-lg-5 col-xl-4">
                    <div className="contact-side-info mb-80">
                        <h2 className="mb-3">What can we <br/> do to help?</h2>
                        <p className="mb-4">Please get in touch with any questions or support you need. We'll respond as soon as possible.</p>
                        <div className="contact-mini-card-wrapper">
                            <div className="contact-mini-card">
                                <div className="contact-mini-card-icon"><i className="lni lni-envelope"></i></div>
                                <p>support@boxup.io</p>
                            </div>
                            <div className="contact-mini-card">
                                <div className="contact-mini-card-icon"><i className="lni lni-headphone-alt"></i></div>
                                <p>Chat Support is in-app</p>
                            </div>
                            <div className="contact-mini-card">
                                <div className="contact-mini-card-icon"><i className="lni lni-alarm-clock"></i></div>
                                <p>We are available 24/7</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-7">
                    <div className="contact-form mb-80">
                        <form onSubmit={submit} method="POST">
                            <div id="success_fail_info"></div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="name">Full Name:</label>
                                        <input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            className="form-control mb-30" 
                                            placeholder="Your name" 
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <label htmlFor="email">Email Address:</label>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            id="email" 
                                            className="form-control mb-30" 
                                            placeholder="Email" 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="subject">Subject:</label>
                                        <input 
                                            type="text" 
                                            name="subject" 
                                            id="subject" 
                                            className="form-control mb-30" 
                                            placeholder="Subject" 
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="message">Message:</label>
                                        <textarea 
                                            name="message" 
                                            id="message" 
                                            className="form-control mb-30" 
                                            placeholder="What can we help you with?" 
                                            rows="8" 
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="col-12 text-center">
                                        {!loading &&
                                        <button className="btn saasbox-btn w-100" type="submit">Send</button>
                                        }
                                        {loading &&
                                        <button className="btn saasbox-btn w-100" type="button" disabled>
                                            <img src="/img/loader.gif" style={{height: '20px'}}/>
                                        </button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(Contact);