import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function BoxUpNews(props) {

    useEffect(() => {
        loadjs('/js/active.js');
        props.updateMetaTags({
            title: 'BoxUp has got to work - BoxUp Shopify App Development',
            description: "Building apps to make things simpler for you.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Unify app, shopify app developer, shopify apps'
                }
            }
        })
    }, []);
    
    return <>
        <div className="saasbox--blog--area section-padding-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8">
                        <div className="single-blog-details-area">
                            <img className="post-thumbnail mb-5" src="/img/boxup_news.png" alt="Box Builder app is live" style={{height:'200px'}}/>
                            <div className="post-date mb-2">July, 2020</div>
                            <h2 className="mb-3">We're getting things started</h2>
                            <div className="post-meta mb-5">
                                <a className="post-author" href="#">By Andy</a><a className="post-tutorial" href="#">BoxUp</a>
                            </div>
                            <p>BoxUp plans to build some awesome Shopify apps to make things simpler for store owners.</p>
                            <p>
                                The apps we'll be looking to create will start by focusing on sales conversion and marketing. We hope to significantly increase the 
                                marketing reach of our customers by following through will our unique ideas.
                            </p>
                            <p>
                                The first app we will create, the Box Builder, will focus on improving conversion rates for stores looking to offer a build-a-box 
                                solution for their customers. Providing a much better user experience than simply telling the customer which products to add to 
                                form a box.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="cta-area cta3 py-5">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-sm-8">
                        <div className="cta-text mb-4 mb-sm-0">
                        <h4 className="text-white mb-0">Start with a 7 day free trial.</h4>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 text-sm-right">
                        <a className="btn saasbox-btn white-btn" target="_blank" href="https://apps.shopify.com/boxup-product-builder">
                            Install Now
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(BoxUpNews);