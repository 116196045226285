import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function BoxBuilderRelease(props) {

    useEffect(() => {
        loadjs('/js/active.js');
        props.updateMetaTags({
            title: 'Our Box Builder app is live! - BoxUp Shopify App Development',
            description: "The Box Builder app is great for building gift boxes or custom products through your Shopify store.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Unify app, shopify app developer, shopify apps'
                }
            }
        })
    }, []);
    
    return <>
        <div className="saasbox--blog--area section-padding-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8">
                        <div className="single-blog-details-area">
                            <img className="post-thumbnail mb-5" src="/img/LIVE.png" alt="Box Builder app is live" style={{height:'200px'}}/>
                            <div className="post-date mb-2">Sep, 2020</div>
                            <h2 className="mb-3">Our Box Builder app is live!</h2>
                            <div className="post-meta mb-5">
                                <a className="post-author" href="#">By Andy</a><a className="post-tutorial" href="#">BoxUp</a>
                            </div>
                            <p>It feels great to annouce that, after a few weeks of development, the Box Builder Shopify app is now live on the Shopify app store.</p>
                            <p>
                                If you sell gift boxes, custom products or want to offer your customers a step-by-step/build-a-box process when purchasing from your store, 
                                our Box Builder will help you with just that.
                            </p>
                            <p>
                                The idea was born from a friend of mine. She needed this exact functionality but found no help from existing apps. We initially recommended 
                                some well known apps that may help her get the job done, but she was disappointed with how much technically knowledge was required and also the 
                                front-end design of the other products.
                            </p>
                            <p>
                                We'd love to hear feedback from you and feel free to contact us regarding any additional features you'd like to see.
                            </p>
                            <h4 className="mb-3 mt-5">The App's Features</h4>
                            <p>
                                <ul className="circle-list">
                                    <li>Create an unlimited number of builders.</li>
                                    <li>Collect extra information via text, date, numeric or drop-down form fields.</li>
                                    <li>Two builder theme options.</li>
                                    <li>In-app chat support.</li>
                                    <li>7 day free trial.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="cta-area cta3 py-5">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-sm-8">
                        <div className="cta-text mb-4 mb-sm-0">
                        <h4 className="text-white mb-0">Start with a 7 day free trial.</h4>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 text-sm-right">
                        <a className="btn saasbox-btn white-btn" target="_blank" href="https://apps.shopify.com/boxup-product-builder">
                            Install Now
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(BoxBuilderRelease);