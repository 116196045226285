import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function News(props) {


    useEffect(() => {
        loadjs('/js/active.js');
        props.updateMetaTags({
            title: 'Latest News - BoxUp Shopify App Development',
            description: "Building apps to make things simpler for you.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Shopify, shopify app developer, shopify apps, news'
                }
            }
        })
    }, []);
    
    return <>
        <div className="breadcrumb--area white-bg-breadcrumb">
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                    <h2 className="breadcrumb-title">BoxUp's News</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">News</li>
                        </ol>
                    </nav>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="saasbox--blog--area blog-card-page section-padding-120">
            <div className="container">
                <div className="row justify-content-center g-5">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                        <div className="card blog-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                            <Link to="/news/box-builder-release"><img className="card-img-top" src="img/LIVE.png" alt=""/></Link>
                            <div className="post-content p-4">
                                <Link className="d-block text-muted mb-2" to="/news/box-builder-release">Sep 2020</Link>
                                <Link className="post-title d-block mb-2" to="/news/box-builder-release">
                                    <h4>Our Box Builder app is live!</h4>
                                </Link>
                                <p>Start with a 7 day free trial and let us know what you think.</p>
                                <div className="post-meta d-flex align-items-center justify-content-between">
                                    <Link className="post-author" to="/news/box-builder-release">
                                        <img src="img/boxup.png" alt=""/>
                                    </Link>
                                    <span className="text-muted">1 min read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                        <div className="card blog-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                            <Link to="/news/unify-development"><img className="card-img-top" src="img/development_unify.png" alt=""/></Link>
                            <div className="post-content p-4">
                                <Link className="d-block text-muted mb-2" to="/news/unify-development">Sep 2020</Link>
                                <Link className="post-title d-block mb-2" to="/news/unify-development">
                                    <h4>Unify app now in development.</h4>
                                </Link>
                                <p>We've completed the design phase of our Unify app.</p>
                                <div className="post-meta d-flex align-items-center justify-content-between">
                                    <Link className="post-author" to="/news/unify-development">
                                        <img src="img/boxup.png" alt=""/>
                                    </Link>
                                    <span className="text-muted">1 min read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                        <div className="card blog-card wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                            <Link to="/news/boxup-news"><img className="card-img-top" src="img/boxup_news.png" alt=""/></Link>
                            <div className="post-content p-4">
                                <Link className="d-block text-muted mb-2" to="/news/boxup-news">July 2020</Link>
                                <Link className="post-title d-block mb-2" to="/news/boxup-news">
                                    <h4>We're getting things started.</h4>
                                </Link>
                                <p>BoxUp plans to build some awesome Shopify apps.</p>
                                <div className="post-meta d-flex align-items-center justify-content-between">
                                    <Link className="post-author" to="/news/boxup-news">
                                        <img src="img/boxup.png" alt=""/>
                                    </Link>
                                    <span className="text-muted">2 min read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(News);