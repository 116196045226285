import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

function Layout(props) {
    let year = (new Date()).getFullYear();

    useEffect(() => {
        var $navbar = window.$(".main-nav"),
            stickyPoint = 90;
        window.$(window).on("scroll", function() {
            if (window.$(window).scrollTop() >= stickyPoint) {
                $navbar.addClass("navbar-sticky");
            } else {
                $navbar.removeClass("navbar-sticky");
            }
        });
    });

    return <>
        <DocumentMeta {...props.meta.tags}>
        <header className="header-area header2">
            <div className="container">
                <div className="classy-nav-container breakpoint-off">
                    <nav className="classy-navbar navbar2 justify-content-between" id="saasboxNav">
                        <Link className="nav-brand mr-5" to="/"><img src="/img/logo.png" alt="BoxUp Logo" style={{height: '50px'}}/></Link>
                        <div className="classy-navbar-toggler"><span className="navbarToggler"><span></span><span></span><span></span><span></span></span></div>
                        <div className="classy-menu">
                        <div className="classycloseIcon">
                            <div className="cross-wrap"><span className="top"></span><span className="bottom"></span></div>
                        </div>
                        <div className="classynav">
                            <ul id="corenav">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/news">News</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                            <div className="login-btn-area ml-4 mt-4 mt-lg-0">
                                <a className="btn saasbox-btn btn-sm" href="https://apps.shopify.com/partners/applow" target="_blank">Our Apps</a>
                            </div>
                        </div>
                        </div>
                    </nav>
                </div>
            </div>
            </header>
            {props.children}
            <footer className="footer-area footer2 section-padding-120">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-sm-10 col-lg-3">
                            <div className="footer-widget-area mb-70">
                                <Link className="d-block mb-4" to="/"><img src="/img/boxup-light.png" alt="BoxUp Logo Light" style={{height: '50px'}}/></Link>
                                <p>Building Shopify apps to make things simpler for you and your business.</p>
                                <div className="footer-social-icon d-flex align-items-center">
                                    <a href="https://www.facebook.com/boxup.io" data-toggle="tooltip" data-placement="top" title="Facbook" target="_blank">
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                    <a href="https://twitter.com/BoxUpApps" data-toggle="tooltip" data-placement="top" title="Twitter" target="_blank">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                    <a href="https://www.instagram.com/BoxUpApps" data-toggle="tooltip" data-placement="top" title="Instagram" target="_blank">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="footer-widget-area mb-70">
                            <h5 className="widget-title">BoxUp</h5>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><a href="#">Unify App</a></li>
                                <li><a href="https://apps.shopify.com/boxup-product-builder" target="_blank">Box Builder</a></li>
                            </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="footer-widget-area mb-70">
                                <h5 className="widget-title">Important Links</h5>
                                <ul>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/news">Latest News</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <div className="footer-widget-area mb-70">
                            <h5 className="widget-title">Shopify Help</h5>
                            <ul>
                                <li><a href="https://www.shopify.com/blog/what-is-shopify" target="_blank">What is Shopify?</a></li>
                                <li><a href="https://www.shopify.com/?ref=applow" target="_blank">Sign Up</a></li>
                                <li><a href="https://www.shopify.com/online/ecommerce-solutions" target="_blank">Features</a></li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <div className="footer--content-text">
                                    <p className="mb-0">All rights reserved by BoxUp</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="footer-nav">
                                    <ul className="d-flex">
                                        <li><Link to="/privacy">Privacy Policy</Link></li>
                                        <li><Link to="/contact">Help & Support</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        </DocumentMeta>
    </>
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(Layout);