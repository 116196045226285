export const updateMetaTags = (tags) => {
    return {
        type: "SET_TAGS",
        payload: {
            tags
        }
    }
}

export const resetMetaTags = () => {
    return {
        type: "RESET_TAGS"
    }
}