import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateMetaTags } from './../actions';

var loadjs = require('loadjs');

function UnifyDevelopment(props) {

    useEffect(() => {
        loadjs('/js/active.js');
        props.updateMetaTags({
            title: 'Unify Shopify app is in development - BoxUp Shopify App Development',
            description: "Building apps to make things simpler for you.",
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'BoxUp, Unify app, shopify app developer, shopify apps'
                }
            }
        })
    }, []);
    
    return <>
        <div className="saasbox--blog--area section-padding-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8">
                        <div className="single-blog-details-area">
                            <img className="post-thumbnail mb-5" src="/img/development_unify.png" alt="Unify app in development" style={{height:'200px'}}/>
                            <div className="post-date mb-2">Sep, 2020</div>
                            <h2 className="mb-3">Unify app now in development</h2>
                            <div className="post-meta mb-5">
                                <a className="post-author" href="#">By Andy</a><a className="post-tutorial" href="#">BoxUp</a>
                            </div>
                            <p>We've completed the design phase of our Unify app and we're now taking it into development.</p>
                            <p>
                                We plan to have the app released before the end of this year (2020). Until then, we're keeping a lot of detail under wraps but we can say 
                                that the purpose of the app is to provide a new, free and excellent marketing opportunities.
                            </p>
                            <p>
                                More detail to be released here as development continues.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = state => {
    return state;
}

const actions = {
    updateMetaTags
}

export default connect(
    mapStateToProps,
    actions
)(UnifyDevelopment);