import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// layout routes
import Layout from './layout';
import Home from './pages/';
import Contact from './pages/contact';
import Privacy from './pages/privacy';

import News from './pages/news';
import BoxBuilderRelease from './blog/builder-release';
import UnifyDevelopment from './blog/unify-development';
import BoxupNews from './blog/boxup-news';

class App extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    <Layout>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/news" component={News}/>
                        <Route exact path="/news/box-builder-release" component={BoxBuilderRelease}/>
                        <Route exact path="/news/unify-development" component={UnifyDevelopment}/>
                        <Route exact path="/news/boxup-news" component={BoxupNews}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/privacy" component={Privacy}/>
                    </Layout>
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(App);